import axios, {CreateAxiosDefaults} from 'axios';
import Cookies from 'js-cookie';

const config: CreateAxiosDefaults = {
  baseURL: ENV_HOT_API,
  timeout: 0,
};

if (process.env.NODE_ENV === 'development') {
  config.headers = {
    Authorization: `Bearer ${Cookies.get('auth_token')}`,
  };
}

if (process.env.NODE_ENV === 'production') {
  config.withCredentials = true;
}

const instance = axios.create(config);

export default instance;
