export enum PersonalAreaUrl {
  ProfilePage = '/',
  BusinessConfirm = '/business-confirm',
  BusinessClaim = '/business-claim',
  MyQuestions = '/my-questions',
  MyReviews = '/my-reviews',
  MyBusiness = '/my-business/',
  MyBusinessWizard = '/my-business/wizard/:businessId',
  MyBusinessEdit = '/my-business/edit/:businessId',
  AdvertiseOnHot = '/advertise-on-hot',
  CreateImageAd = '/create-image-ad',
  CreateTextAd = '/create-text-ad',
  CreateUrlAd = '/create-url-ad',
  CreateTopSpotAd = '/create-top-spot-ad',
  CreatePromotion = '/create-promotion',
}

export enum ExternalUrl {
  SignIn = '/signin',
  Dashboard = '/profile/dashboard',
  Notifications = '/profile/notifications',
  ChangePassword = '/site/change-password',
  ChangePhone = '/site/change-phone',
  ChangeEmail = '/site/change-email',
  Faq = 'https://help.hot.com',
  Logout = '/logout',
  DummyLink = '#',
}
