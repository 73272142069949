import {useSelector as useSelectorNative, useDispatch as useDispatchNative} from 'react-redux';
import loadingPlugin from '@rematch/loading';
import {init} from '@rematch/core';

import {models, RootModel} from './models';
import {trackLoadingWhitelist} from './constants';
import {Dispatch, FullModel, TCustomUseSelector} from './types';

export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    loadingPlugin({
      whitelist: trackLoadingWhitelist,
      type: 'full',
    }),
  ],
});

export type Store = typeof store;

export const useSelector: TCustomUseSelector = useSelectorNative;
export const useDispatch = useDispatchNative<Dispatch>;
