import {createModel} from '@rematch/core';

import {TProfile, requestProfile} from '~/services/account';
import {
  TNotificationsResponse,
  fetchUserNotifications,
  setUserNotificationAsReaded,
  removeNotification,
} from '~/services/hot-api/notifications';

import type {RootModel} from '.';

export interface ProfileState {
  data: TProfile | null;
  notifications: {
    response: TNotificationsResponse;
    loading: boolean;
    timerId: NodeJS.Timer | null;
  };
  loading: boolean;
  isGuest: boolean;
}

const REFRESH_NOTIFICATIONS_TIMER = 1000 * 60 * 5; // 5 min

const defaultState: ProfileState = {
  data: null,
  notifications: {
    response: {
      total: 0,
      unviewed: 0,
      items: [],
    },
    loading: true,
    timerId: null,
  },
  loading: true,
  isGuest: true,
};

export const profile = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    setLoading: (state: ProfileState) => ({
      ...state,
      loading: true,
    }),
    setGuest: (state: ProfileState, isGuest: boolean) => ({
      ...state,
      isGuest,
      loading: !isGuest,
    }),
    setProfileData: (state: ProfileState, data: any) => ({
      ...state,
      data,
      loading: false,
    }),
    setNotifcationsLoading: (state: ProfileState) => ({
      ...state,
      notifications: {
        ...state.notifications,
        loading: true,
      },
    }),
    setNotifications: (state: ProfileState, notifications: TNotificationsResponse) => ({
      ...state,
      notifications: {
        ...state.notifications,
        response: notifications,
        loading: false,
      },
    }),
    setNotificationtimer: (state, timerId: NodeJS.Timer) => ({
      ...state,
      notifications: {
        ...state.notifications,
        timerId,
      },
    }),
    markNotificationAsReaded: (state: ProfileState, notificationId: number) => ({
      ...state,
      notifications: {
        ...state.notifications,
        response: {
          ...state.notifications.response,
          items: state.notifications.response.items.map((notification) => {
            if (notificationId === notification.id) {
              return {
                ...notification,
                readed: true,
              };
            }
            return notification;
          }),
          unviewed: state.notifications.response.unviewed - 1,
        },
      },
    }),
    removeSignleNotification: (state, notificationId: number) => {
      const {response} = state.notifications;
      const notification = response.items.find(({id}) => id === notificationId);

      if (!notification) {
        return state;
      }
      const isNotificationWasReaded = notification.viewed || notification.readed;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          response: {
            ...response,
            items: response.items.filter(({id}) => id !== notificationId),
            total: response.total - 1,
            unviewed: isNotificationWasReaded ? response.unviewed : response.unviewed - 1,
          },
        },
      };
    },
  },

  effects: (dispatch) => {
    window.addEventListener('DOMContentLoaded', () => {
      dispatch.profile.getGuest();
    });

    return {
      async getProfile(): Promise<void> {
        dispatch.profile.setLoading();

        try {
          const response = await requestProfile();

          dispatch.profile.setProfileData(response.data);
        } catch (e) {
          console.error(e);
        }
      },
      async loadNotifications() {
        dispatch.profile.setNotifcationsLoading();
        const notifications = await fetchUserNotifications();

        dispatch.profile.setNotifications(notifications.data);
      },
      async setNotifactionAsReaded(notificationId: number, state) {
        // Negative counter protection
        const notification = state.profile.notifications.response.items.find(
          ({id}) => id === notificationId
        );

        if (!notification || notification.readed || notification.viewed) {
          return;
        }

        // call to mark on remote
        try {
          await setUserNotificationAsReaded(notificationId);
        } catch (e) {
          console.error(e);
        }

        // mark locally
        dispatch.profile.markNotificationAsReaded(notificationId);
      },
      async removeNotification(notificationId: number) {
        // remove locally
        dispatch.profile.removeSignleNotification(notificationId);

        // remove on remote
        try {
          await removeNotification(notificationId);
        } catch (e) {
          console.error(e);
        }
      },
      async loadMoreNotifications(_: void, state) {
        dispatch.profile.setNotifcationsLoading();
        try {
          const currentNotificationsList = state.profile.notifications.response.items;
          const {data} = await fetchUserNotifications({
            offset: currentNotificationsList.length,
          });

          dispatch.profile.setNotifications({
            ...data,
            items: [...currentNotificationsList, ...data.items],
          });
        } catch (e) {
          console.error(e);

          dispatch.profile.setNotifications(state.profile.notifications.response);
        }
      },
      async startNotificationsPulling(_: void, state) {
        const {timerId} = state.profile.notifications;
        if (timerId) {
          clearInterval(timerId);
        }

        const newTimerId = setInterval(() => {
          dispatch.profile.loadNotifications();
        }, REFRESH_NOTIFICATIONS_TIMER);

        dispatch.profile.setNotificationtimer(newTimerId);
      },
      async getGuest() {
        const isGuest = window.__IS_GUEST__;

        dispatch.profile.setGuest(isGuest);

        if (!isGuest) {
          dispatch.profile.getProfile();
        }
      },
    };
  },
});
