export enum Modals {
  ChooseRoleModal = 'ChooseRoleModal',
  BusinessVerificationSuccess = 'BusinessVerificationSuccess',
  BusinessCodeOutdated = 'BusinessCodeOutdated',
  PlacePreviewModal = 'PlacePreviewModal',
  PlaceEditWizardSuccess = 'PlaceEditWizardSuccess',
  ContactUsModal = 'ContactUsModal',
  RemovalRequestModal = 'RemovalRequestModal',
  ImagePreview = 'ImagePreviewModal',
}

export const SERVER_ERROR = 'Sorry, some server error occurred. Please try again later.';

/**
 * Optional query params to submit with contact us form
 */
export const ContactUsQueryParams = ['call_id', 'place_id'];

export const CONTACT_US_CAPTCHA_NAMESPACE = 'modal-namespace';
export const CONTACT_US_CAPTCHA_ID = 'captcha-contact-us';

export const REMOVAL_REQUEST_CAPTCHA_NAMESPACE = 'modal-namespace';
export const REMOVAL_REQUEST_CAPTCHA_ID = 'captcha-removal-request';
