import {createModel} from '@rematch/core';

import {Modals} from '~/modals/constants';

import type {RootModel} from '.';

export type ModalsState = {
  [key in Modals]: {
    visible: boolean;
    payload: any;
  };
};

const query = new URLSearchParams(window.location.search);
const verification = query.get('claim-business') || '';

const defaultState = {
  [Modals.BusinessVerificationSuccess]: {
    visible: Boolean(verification === 'success'),
    payload: null,
  },
  [Modals.BusinessCodeOutdated]: {
    visible: false,
    payload: null,
  },
  [Modals.PlacePreviewModal]: {
    visible: false,
    payload: null,
  },
  [Modals.ChooseRoleModal]: {
    visible: false,
    payload: null,
  },
  [Modals.PlaceEditWizardSuccess]: {
    visible: true,
    payload: null,
  },
  [Modals.ContactUsModal]: {
    visible: false,
    payload: null,
  },
  [Modals.RemovalRequestModal]: {
    visible: false,
    payload: null,
  },
  [Modals.ImagePreview]: {
    visible: false,
    payload: {
      images: [],
      index: -1,
    },
  },
};

export const modals = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    showModal: (state, key: Modals | {key: Modals; payload?: any}) => {
      if (typeof key === 'object') {
        return {
          ...state,
          [key.key]: {
            visible: true,
            payload: key.payload,
          },
        };
      }

      return {
        ...state,
        [key]: {
          visible: true,
          payload: null,
        },
      };
    },
    setPayload: (state, {key, payload}) => ({
      ...state,
      [key]: {
        visible: state[key].visible,
        payload,
      },
    }),
    hideModal: (state, key: Modals) => ({
      ...state,
      [key]: {
        visible: false,
        payload: defaultState[key].payload,
      },
    }),
  },
});
