import {Models} from '@rematch/core';

import {app} from './app';
import {reviews} from './reviews';
import {profile} from './profile';
import {favorites} from './favorites';
import {questions} from './questions';
import {modals} from './modals';
import {business} from './business';
import {promotions} from './promotions';
import {urlPromotion} from './promotions/url';
import {topSpotPromotion} from './promotions/topspot';
import {contactUs} from './modals/contact-us';
import {connectedProfiles} from './connected-profiles';
import {removalRequest} from './modals/removal-request';

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  questions: typeof questions;
  reviews: typeof reviews;
  profile: typeof profile;
  favorites: typeof favorites;
  modals: typeof modals;
  business: typeof business;
  promotions: typeof promotions;
  urlPromotion: typeof urlPromotion;
  topSpotPromotion: typeof topSpotPromotion;
  contactUs: typeof contactUs;
  removalRequest: typeof removalRequest;
  connectedProfiles: typeof connectedProfiles;
}

export const models: RootModel = {
  app,
  reviews,
  profile,
  favorites,
  questions,
  modals,
  business,
  connectedProfiles,
  // Public modals
  contactUs,
  removalRequest,
  // Promotions
  promotions,
  urlPromotion,
  topSpotPromotion,
};
