import {v4 as uuidv4} from 'uuid';

import axios from './index';

export const getMyPlace = (placeId: string) =>
  axios.get('/business-verification/get-place-info', {params: {business_id: placeId}});

export const updatePlace = ({businessId, place}: any) =>
  axios.post('/business-verification/place', {business_id: businessId, ...place});

export const uploadPlaceImage = (formData: any, placeId: any, onUploadProgress: any) => {
  return axios.post(
    `${ENV_IMAGES_SERVICE_HOST}/upload?context=hot_personal_area&img_type=exs&entity_id=${placeId}`,
    formData,
    {
      withCredentials: true,
      onUploadProgress,
    }
  );
};

export const uploadPlaceVideo = (
  formData: any,
  placeId: any,
  onUploadProgress: any,
  signal: any
) => {
  const file = formData.get('file');
  formData.append('uuid', uuidv4());
  formData.append('fileSize', file.size);

  return axios.post(
    `${ENV_ADMIN_SERVICE_HOST}/video/upload?context=hot_personal_area&entity_id=${placeId}`,
    formData,
    {
      withCredentials: true,
      signal,
      onUploadProgress,
    }
  );
};

export const getPlaceVideoInfo = (videoId: any, placeId: any, context = 'hot_personal_area') =>
  axios.get(
    `${ENV_ADMIN_SERVICE_HOST}/video/info?context=${context}&entity_id=${placeId}&video_id=${videoId}`,
    {withCredentials: true}
  );
