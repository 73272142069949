import {UserRoles} from '~/utils/constants';

import axios from './index';
import {UserType} from './types';

export interface TProfile {
  avatar: string | null;
  balance: number;
  canBoostImage: boolean;
  expectedAmount: number;
  firstName: string;
  lastName: string;
  hotlinksUrl: string | null;
  isAdvertiser: boolean;
  isFunded: boolean;
  isProfileVerified: boolean;
  isVerified: boolean;
  liveAds: number;
  mailServerUrl: string | null;
  promotions: Record<number, {name: string; count: number}>;
  questions: number;
  reviews: number;
  roleId: UserRoles;
  userType: UserType;
  user_id: string;
  email: string;
  phone: string;
}

export const requestProfile = () => axios.get<TProfile>('/api/account/about-me');
