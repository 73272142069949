import {PromotionTextTypes} from '~/utils/constants';

import instance from './instance';
import {PromotionCategories} from './types';

export type FetchPromotionCategoriesParams =
  | {
      type: PromotionTextTypes.Text;
      cityIds: number[];
      url?: void;
    }
  | {
      type: PromotionTextTypes.Url;
      url: string;
      cityIds?: void;
    }
  | {
      type: PromotionTextTypes.Image | PromotionTextTypes.TopSpot;
      cityIds?: void;
      url?: void;
    };

export const fetchPromotionCategories = ({type, cityIds, url}: FetchPromotionCategoriesParams) =>
  instance.post<PromotionCategories>(`/frontend/api/v1/category`, {
    ad: type,
    url,
    cityIds: cityIds?.map((v: number | string) => {
      if (typeof v === 'string') {
        return parseInt(v, 10);
      }
      return v;
    }),
  });
