import {createModel} from '@rematch/core';

import {WebsiteThemes} from '~/utils/constants';
import {Modals} from '~/modals/constants';

import {AppConfig, ManageMenuBoostrap} from '../../../types';
import type {RootModel} from '.';

export interface ManageMenu {
  visible: boolean;
  content: ManageMenuBoostrap;
}

export type AppState = {
  theme: WebsiteThemes;
  loading: boolean;
  manageMenu: ManageMenu;
  isMenuLocked: boolean;
  data: AppConfig;
};

const initialConfig: AppConfig = {
  page: {
    isRootPage: false,
    isSearchPage: false,
    isModal: false,
    pageCategory: '',
  },
  mail: {
    display: false,
    href: '',
  },
  impersonate: {
    display: false,
    email: '',
    userEmail: '',
  },
  balance: {
    display: false,
    amount: 0,
    formatedAmount: '',
  },
  logo: {
    display: false,
    title: '',
  },
  searchForm: {
    value: '',
    utmSource: null,
    analyzer: null,
    imageUploadLimits: {
      minHeight: 0,
      minWidth: 0,
      sizeMax: 0,
      sizeMin: 0,
    },
  },
  instanceInfo: {
    label: 'HOT.com',
    countryCode: 'US',
    searchDomain: 'hot.com',
  },
  isNeedUrlObfuscate: false,
  showSearchPhoto: false,
  searchAction: '',
  showNavTabs: false,
  showHeaderActions: false,
};

const defaultState: AppState = {
  theme: window.__WEBSITE_THEME__,
  loading: true,
  data: initialConfig,
  manageMenu: {
    visible: false,
    content: null,
  },
  isMenuLocked: false,
};

export const app = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    setMenuLocked: (state, isLocked: boolean) => ({
      ...state,
      isMenuLocked: isLocked,
    }),
    setBootstrapData: (state, data: Partial<AppState>) => ({
      ...state,
      ...data,
    }),
    showManageMenu: (state, content: ManageMenuBoostrap) => ({
      ...state,
      manageMenu: {
        visible: true,
        content,
      },
    }),
    hideManageMenu: (state) => ({
      ...state,
      manageMenu: {
        visible: false,
        content: null,
      },
    }),
  },
  effects: (dispatch) => {
    window.addEventListener('DOMContentLoaded', () => {
      dispatch.app.bootstrap();
    });

    window.document.addEventListener('MODAL_REACT_SHOW', ({detail}) => {
      if (Modals[detail.id]) {
        dispatch.modals.showModal({key: Modals[detail.id], payload: detail});
      } else {
        console.warn('Modal not found', Modals[detail.id]);
      }
    });

    window.document.addEventListener('MANAGE_MENU_SHOW', ({detail}) => {
      dispatch.app.showManageMenu(detail);
    });

    return {
      async bootstrap() {
        dispatch.app.setBootstrapData({
          data: window.APP_CONFIG,
          loading: false,
          theme: window.__WEBSITE_THEME__,
          manageMenu: {
            content: window.APP_MANAGE_MENU,
            visible: Boolean(window.APP_MANAGE_MENU),
          },
        });
      },
    };
  },
});
