export const trackLoadingWhitelist = [
  'urlPromotion/getPromotionById',
  'promotions/requestLocations',
  'promotions/requestCategories',
  'promoCodes/requestLocations',
  'urlPromotion/checkUrlChangePossibility',
  'urlPromotion/updateUrlPromotion',
  'connectedProfiles/loadPhonesAndProfiles',
  'connectedProfiles/loadMoreProfiles',
  'connectedProfiles/loadReviews',
  'connectedProfiles/loadMoreReviews',
  'connectedProfiles/loadQuestions',
  'connectedProfiles/loadMoreQuestions',
  'connectedProfiles/filterSelect',
  'favorites/loadUserSubscriptions',
];
