import {createModel} from '@rematch/core';
import Cookies from 'js-cookie';

import axios from '~/services/index';
import {refreshCaptca} from '~/utils/captcha';
import {getMyPlace, updatePlace} from '~/services/places';
import {ClaimPlace, MyPlace} from '~/services/types';
import {Modals} from '~/modals/constants';

import type {RootModel} from '.';

export type BusinessState = {
  claimPlace: {
    place: ClaimPlace | null;
    loading: boolean;
    errorMessage: string | null;
  };
  myPlaces: {
    items: any[];
    total: number;
    loading: boolean;
  };
  previewPlace: {
    place: any;
    loading: boolean;
  };
  code: string | null;
  phone: string | null;
  editPlace: {
    place: any | null;
    loading: boolean;
    error: boolean;
  };
  placeToSubmit: any;
};

const defaultState: BusinessState = {
  claimPlace: {
    place: null,
    loading: false,
    errorMessage: null,
  },
  previewPlace: {
    loading: true,
    place: null,
  },
  myPlaces: {
    items: [],
    total: 0,
    loading: true,
  },
  code: null,
  phone: null,
  editPlace: {
    place: null,
    loading: true,
    error: false,
  },
  placeToSubmit: {},
};

export const business = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    setClaimPlaceLoading: (state: BusinessState, loading: boolean) => ({
      ...state,
      claimPlace: {
        ...state.claimPlace,
        loading,
      },
    }),
    setClaimPlace: (state: BusinessState, place: any) => ({
      ...state,
      claimPlace: {
        ...state.claimPlace,
        place,
        errorMessage: null,
      },
    }),
    resetClaimPlace: (state: BusinessState) => ({
      ...state,
      claimPlace: {
        ...defaultState.claimPlace,
      },
    }),
    setMyPlacesLoading: (state: BusinessState, loading: boolean) => ({
      ...state,
      myPlaces: {
        ...state.myPlaces,
        loading,
      },
    }),
    setMyPlaces: (state: BusinessState, myPlaces: any) => ({
      ...state,
      myPlaces: {
        ...myPlaces,
        loading: false,
      },
    }),
    setCode: (state: BusinessState, code: string) => ({
      ...state,
      code,
    }),
    setPhone: (state: BusinessState, phone: string) => ({
      ...state,
      phone,
    }),
    setErrorMessage: (state: BusinessState, errorMessage: string) => ({
      ...state,
      claimPlace: {
        ...state.claimPlace,
        loading: false,
        errorMessage,
      },
    }),
    setEditPlaceLoading: (state: BusinessState) => ({
      ...state,
      editPlace: {
        place: null,
        loading: true,
        error: false,
      },
    }),
    setEditPlace: (state: BusinessState, place: any) => ({
      ...state,
      editPlace: {
        place,
        loading: false,
        error: false,
      },
    }),
    setEditPlaceError: (state: BusinessState) => ({
      ...state,
      editPlace: {
        place: null,
        loading: false,
        error: true,
      },
    }),
    // PreviewPlace
    setPreviewPlace: (state: BusinessState, place: any) => ({
      ...state,
      previewPlace: {
        place,
        loading: false,
      },
    }),
    resetPreviewPlace: (state: BusinessState) => ({
      ...state,
      previewPlace: {
        ...defaultState.previewPlace,
      },
    }),
    addSubmitPlacePayload: (state: BusinessState, payload: any) => ({
      ...state,
      placeToSubmit: {
        ...state.placeToSubmit,
        ...payload,
      },
    }),
    clearSubmitPlacePayload: (state: BusinessState, key?: string) => {
      if (!key) {
        return {
          ...state,
          placeToSubmit: {},
        };
      }
      const placeToSubmit = {...state.placeToSubmit};

      delete placeToSubmit[key];

      return {
        ...state,
        placeToSubmit,
      };
    },
  },
  effects: (dispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const {business, profile, modals} = dispatch;

    return {
      async loadMyBusiness(): Promise<void> {
        business.setMyPlacesLoading(true);

        try {
          const response = await axios.get<MyPlace[]>('/business-verification/my-business');
          business.setMyPlaces(response.data || []);
        } catch (error: any) {
          console.error(error);
        } finally {
          business.setMyPlacesLoading(false);
        }
      },
      async claimCode({
        isPrivate,
        code,
        phone,
        captcha,
      }: {
        isPrivate?: boolean;
        code: string;
        phone: string;
        captcha: string;
      }): Promise<void> {
        business.setClaimPlaceLoading(true);
        try {
          const url = !isPrivate
            ? '/business-verification/get-place-by-code'
            : '/business-verification/get-place-by-code-or-by-phone';
          const response = await axios.get<ClaimPlace & {error: any}>(url, {
            params: {
              ...(code && {code}),
              captcha,
              ...(phone && phone.length > 3 && {phone}),
            },
          });

          if (response.data && response.data?.error) {
            throw new Error(response.data?.error);
          }

          business.setCode(code);
          business.setPhone(phone);
          business.setClaimPlace(response.data);
        } catch (error: any) {
          refreshCaptca('#captcha-business-claim');
          // check for captcha error
          if (error?.response?.data?.error?.message === 'Invalid captcha') {
            business.setErrorMessage('Invalid captcha');
          } else {
            modals.showModal(Modals.BusinessCodeOutdated);
            business.setErrorMessage('');
          }
        } finally {
          business.setClaimPlaceLoading(false);
        }
      },
      async claimBusinessAsUser(businessId, state) {
        const {code, phone} = state.business;
        if (!code && !phone) {
          throw new Error('Place code or phone are not set');
        }

        try {
          business.setClaimPlaceLoading(true);
          // call api method for claim
          await axios.post<void>(`/business-verification/claim-place`, {
            ...(code && {code}),
            ...(phone && phone.length > 3 && {phone}),
          });

          profile.getProfile();

          modals.showModal({key: Modals.BusinessVerificationSuccess, payload: businessId});
        } catch (error: any) {
          modals.showModal(Modals.BusinessCodeOutdated);
        } finally {
          business.setClaimPlaceLoading(false);
        }
      },
      async claimBusiness(businessId, state): Promise<void> {
        const {code, phone} = state.business;

        if (!code && !phone) {
          throw new Error('Place code or phone are not set');
        }

        // set cookie and redirect
        if (state.profile.isGuest && code) {
          const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
          Cookies.set('place-code', code, {expires: inFiveMinutes});
          window.location.href = '/signup';
          return;
        }

        business.claimBusinessAsUser(businessId);
      },
      async loadPreviewPlace(id: string): Promise<void> {
        business.resetPreviewPlace();

        try {
          const response = await getMyPlace(id);

          business.setPreviewPlace(response.data);
        } catch (error: any) {
          console.error(error);
        }
      },
      async loadPlaceToEdit(id: string): Promise<void> {
        business.setEditPlaceLoading();

        try {
          const response = await getMyPlace(id);

          if (response.data?.error) {
            throw new Error('cant load place');
          }
          business.setEditPlace({...response.data});
        } catch (error: any) {
          business.setEditPlaceError();
          console.error(error);
        }
      },
      async updatePlace({businessId, place}): Promise<void> {
        try {
          const response = await updatePlace({businessId, place});

          business.setEditPlace(response.data);
        } catch (error: any) {
          console.error(error);
        }
      },
    };
  },
});
