import UAParser from 'ua-parser-js';

const parser = new UAParser();

export const isIos = () => parser.getOS().name === 'iOS';

export const isMobileDevice = () => parser.getDevice().type === 'mobile';

export const isTabletDevice = () => parser.getDevice().type === 'tablet';

export const deviceDebug = (toAlert = false) => {
  const result = parser.getResult();

  if (toAlert) {
    let text = '';

    Object.keys(result).forEach((key) => {
      if (typeof result[key] === 'object') {
        text += `\n\n${key.toUpperCase()}\n`;

        Object.keys(result[key]).forEach((subKey) => {
          text += `${subKey}: ${result[key][subKey]}, `;
        });
      } else {
        text = `${key.toUpperCase()}:\n ${result[key]}`;
      }
    });

    alert(text);
  } else {
    console.log(result);
  }
};
