import {PromotionTextTypes} from '~/utils/constants';

import instance from './instance';

export enum NotificationActionType {
  Button = 'button',
  AllPromoControl = 'all-promo-control',
}

interface ButtonPayload {
  url: string;
  label: string;
}

interface AllPromoPayload {
  createdPromo: PromotionTextTypes;
}

export type NotificationPayloadMap = {
  [NotificationActionType.Button]: ButtonPayload;
  [NotificationActionType.AllPromoControl]: AllPromoPayload;
};

export interface TNotificationAction<T extends keyof NotificationPayloadMap> {
  type: T;
  payload: Pick<NotificationPayloadMap, T>;
}

export enum NotificationType {
  error = 'error',
  success = 'success',
  info = 'info',
}

export interface TNotification {
  id: number;
  title: string;
  content: string;
  added: string;
  actions: Array<
    | TNotificationAction<NotificationActionType.Button>
    | TNotificationAction<NotificationActionType.AllPromoControl>
  >;
  viewed?: string;
  type?: NotificationType;
  readed?: boolean;
}

export interface TNotificationsResponse {
  total: number;
  unviewed: number;
  items: TNotification[];
}

interface FetchUserNotificationsParams {
  offset?: number;
  limit?: number;
}

export const fetchUserNotifications = ({offset, limit}: FetchUserNotificationsParams = {}) =>
  instance.get<TNotificationsResponse>('/frontend/api/v1/notifications', {
    params: {
      offset,
      limit,
    },
  });

export const setUserNotificationAsReaded = (notificationId: number) =>
  instance.patch(`/frontend/api/v1/notifications/mark-as-read/${notificationId}`);

export const removeNotification = (notificationId: number) =>
  instance.delete(`/frontend/api/v1/notifications/${notificationId}`);
